.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.conference_date{
  text-align: center;
  font-size: 20px;

}
#icita_anchor{
  color:white;

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@import url('https://fonts.googleapis.com/css?family=Muli:300,700|Montserrat');

body {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: #888;
    line-height: 30px;
    text-align: center;
}

strong { font-weight: 600; }

a, a:hover, a:focus {
    color: #9b59b6;
    text-decoration: none;
    transition: all .3s;
}
 #call{
    padding-top: 100px;

      scroll-behavior: smooth;
}

h1, h2 {
    margin-top: 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 38px;
    font-weight: 300;
    color: #555;
    line-height: 50px;
    text-align: center;
}


/***** Top menu *****/

.navbar {
    background: #444;
    -o-transition: all .6s;
    -moz-transition: all .6s;
    -webkit-transition: all .6s;
    -ms-transition: all .6s;
    transition: all .6s;
    backface-visibility: hidden;
    line-height: 35px;
}

.navbar.navbar-no-bg {

    background: rgba(0, 0, 0, 0.5);
}

.navbar-dark .navbar-nav {

    font-size: 13px;
    color: #fff;
    font-weight: 600;
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff;
    color: rgba(255, 255, 255, 0.8);
    border: 0;
}

.navbar-dark .navbar-nav .nav-link:hover {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link:focus {
    color: #fff;
    outline: 0;
}

.navbar-expand-md .navbar-nav .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
}


.navbar li{
    padding-right: 12px;
    padding-left: 12px;
}

/***** Top content *****/

.top-content2021 {
	background-image: url("./../assets/dubai-sky.jpg");
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center;
    padding: 210px 0;
}

.top-content2021 .text {
    color: white;
   
}

.top-content2021 .text h1 {
    margin-top: 25px;
    color: #fff;
}

.top-content2021 .description2021 {
    margin: 30px 0 10px 0;
}

.top-content2021 .description2021 p {
    opacity: 0.8;
}

.top-content2021 .top-big-link {
    margin-top: 45px;
}

#countdown {
       text-align: center;
         font-size: 20px;
         color: white;


}


 #dark-bg {
    background-color: #f9f9f9;
  }


  #call h2{
    padding-bottom: 30px;
  }

 
.org h1{
  font-weight: bold;
  font-size: 24px;
  text-align: left;
}
  .org h2{
    font-size: 20px;
    text-align: left;
  }


  .navbar {
     border-bottom: 2px solid #e48400;
    -webkit-box-shadow: 0 8px 6px -6px #999;
    -moz-box-shadow: 0 8px 6px -6px #999;
    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);

    /* the rest of your styling */
}


.navbar2021 {
  border-bottom: 2px solid #e48400 !important;
 -webkit-box-shadow: 0 8px 6px -6px #999;
 -moz-box-shadow: 0 8px 6px -6px #999;
 box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);

 /* the rest of your styling */
}

.callPapers{
  text-align: left;
  color: black;
}


.home_container {
  background-image: url("./../assets/page-carousel.jpg");
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
    padding: 210px 0;
    
}



.home_container .text {
    color: #fff;
}

.home_container .text h1 {
    margin-top: 25px;
    color: #fff;
}

.home_container .description2021 {
    margin: 30px 0 10px 0;
}

.description2021 p {
    opacity: 0.8;
}

.home_container .top-big-link {
    margin-top: 45px;
}

.event{

  text-align: left;
}

.event p{
  font-weight:  bold;
}

.event h2{
  text-align: left;
  font-size: 25px;
}

.timelabel h1{
  font-size: 32px;
}


.description2021{
  padding:20px;
  background: rgba(5, 5, 5, 0.3);
}

.footer-dark{
  background-color:  rgb(15, 15, 15);
  color: white;
  padding: 15px;
  opacity: 0.9;
  border-top: 2px solid #e48400;
}

.footer2021{
  background-color:  rgb(15, 15, 15);
  color: white;
  padding: 15px;
  opacity: 0.9;
  border-top: 2px solid #e48400 !important;
}


.footer-dark h2{
  color: white;
}
.footer-bottom{
  background-color: #0017e4;
}

hr{
  background-color: white;
}
#keynote {
  float: left;
  width:  200px;
  height: 200px;
  background-size: cover;
}
